import React, {
  ReactElement,
  useState,
  ChangeEvent,
  InvalidEvent,
} from 'react';
import {
  Box,
  FormControl,
  FormControlProps,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightAddon,
  VisuallyHidden,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { Button } from '../Button';
import { TRANS } from './misc';

export type InputTextProps = {
  type?: 'text' | 'email' | 'url' | 'password';
  inputProps?: Omit<InputProps, 'as'>;
  componentLocale?: Locale;
} & FormControlProps;

export const InputText = ({
  label,
  type = 'text',
  inputProps = {},
  componentLocale,
  ...rest
}: InputTextProps): ReactElement => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const { locale, lang } = useLocale(componentLocale);

  const { onChange, onInvalid, ...inputRest } = inputProps;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.checkValidity();
    onChange && onChange(event);
  };

  const handleInvalid = (event: InvalidEvent<HTMLInputElement>) => {
    const {
      valueMissing,
      tooShort,
      tooLong,
      typeMismatch,
      patternMismatch,
      badInput,
    } = event.target.validity;
    event.target.setCustomValidity(
      valueMissing
        ? TRANS.invalidValueMissing[locale]
        : tooShort
        ? TRANS.invalidTooShort[locale]
        : tooLong
        ? TRANS.invalidTooLong[locale]
        : typeMismatch && type === 'email'
        ? TRANS.invalidTypeMismatchEmail[locale]
        : typeMismatch && type === 'url'
        ? TRANS.invalidTypeMismatchUrl[locale]
        : patternMismatch
        ? TRANS.invalidPatternMismatch[locale]
        : badInput
        ? TRANS.invalidOther[locale]
        : ''
    );
    onInvalid && onInvalid(event);
  };

  return (
    <Box lang={lang} marginY={'1em'}>
      <FormControl {...rest}>
        <FormLabel color={'tealCarnation'}>{label}</FormLabel>
        <InputGroup>
          <Input
            autoComplete={'off'}
            onChange={handleChange}
            onInvalid={handleInvalid}
            type={type === 'password' && show ? 'text' : type}
            borderColor={'tealCarnation'}
            borderWidth={2}
            focusBorderColor={'tealCarnation'}
            height={'2.5em'}
            paddingX={'0.7em'}
            _hover={{ borderWidth: 3 }}
            _focus={{ borderWidth: 3 }}
            _active={{ borderWidth: 3 }}
            {...inputRest}
          />
          {type === 'password' && (
            <InputRightAddon
              backgroundColor={'white'}
              borderColor={'tealCarnation'}
              borderWidth={2}
              height={'2.5em'}
            >
              <Button
                variant={'ghost'}
                borderRadius={'50%'}
                borderWidth={2}
                height={'2em'}
                width={'2em'}
                padding={0}
                onClick={handleClick}
              >
                {show ? <ViewIcon aria-hidden /> : <ViewOffIcon aria-hidden />}
                <VisuallyHidden>{TRANS.toggle[locale]}</VisuallyHidden>
              </Button>
            </InputRightAddon>
          )}
        </InputGroup>
      </FormControl>
    </Box>
  );
};
