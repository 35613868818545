import React, {
  Dispatch,
  FormEvent,
  ReactElement,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import { Box, Center } from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { Button } from '../Button';
import { InputText } from '../InputText';
import { Link } from '../Link';
import { Paragraph } from '../Paragraph';
import { SmartLineBreak } from '../SmartLineBreak';
import { Strong } from '../Strong';

const LoginBox = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <Center minHeight={'calc(100vh - 8em)'}>
      <Box
        borderColor={'mintRose'}
        borderWidth={'0.25rem'}
        padding={'10%'}
        textAlign={'center'}
        width={'100%'}
      >
        {children}
      </Box>
    </Center>
  );
};

export type LoginProps = {
  setAfterSuccess?: Dispatch<SetStateAction<boolean>>;
};

export const RegistrationForm = ({
  setAfterSuccess,
}: LoginProps): ReactElement => {
  const identity = useIdentityContext();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (
    event: {
      target: {
        elements: { password1: HTMLInputElement; password2: HTMLInputElement };
      };
    } & FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    setLoading(true);
    const { password1, password2 } = event.target.elements;

    if (password1.value !== password2.value) {
      setLoading(false);
      setError('Error: the passwords you entered don’t match.');
      return;
    }

    await identity
      .completeUrlTokenTwoStep({ password: password1.value })
      .then(() => {
        setError('');
        password1.value = '';
        password2.value = '';
        setAfterSuccess && setAfterSuccess(true);
      })
      .catch((error: Error) => setError(`${error.name}: ${error.message}`))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <LoginBox>
      <form onSubmit={handleSubmit}>
        <InputText
          id={'password1'}
          label={'Please enter your new password'}
          isRequired
        />
        <InputText
          id={'password2'}
          label={'Please enter it again'}
          isRequired
        />
        {error && (
          <Paragraph color={'tealCarnation'}>
            <WarningTwoIcon marginBottom={'0.2em'} marginEnd={'0.2em'} />
            {error}
          </Paragraph>
        )}
        <Button type={'submit'} isLoading={loading} marginTop={'1.5em'}>
          Create
        </Button>
      </form>
    </LoginBox>
  );
};

export const LoginForm = ({ setAfterSuccess }: LoginProps): ReactElement => {
  const identity = useIdentityContext();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (
    event: {
      target: {
        elements: { email: HTMLInputElement; password: HTMLInputElement };
      };
    } & FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoading(true);
    const { email, password } = event.target.elements;

    await identity
      .login({ email: email.value, password: password.value })
      .then(() => {
        setError('');
        email.value = '';
        password.value = '';
        setAfterSuccess && setAfterSuccess(true);
      })
      .catch((error: Error) =>
        error.message.includes(
          'No user found with that email, or password invalid'
        )
          ? setError(error.message)
          : setError(
              'The authentication server returns an error. Please wait a few minutes and try again.'
            )
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <LoginBox>
      <form onSubmit={handleSubmit}>
        <InputText id={'email'} label={'Email'} type={'email'} isRequired />
        <InputText
          id={'password'}
          label={'Password'}
          type={'password'}
          isRequired
        />
        {error && (
          <Paragraph color={'tealCarnation'}>
            <WarningTwoIcon
              aria-hidden
              marginBottom={'0.2em'}
              marginEnd={'0.2em'}
            />
            {error}
          </Paragraph>
        )}
        <Button type={'submit'} isLoading={loading} marginTop={'1.5em'}>
          Log in
        </Button>
      </form>
    </LoginBox>
  );
};

export const LoggedIn = (): ReactElement => {
  const identity = useIdentityContext();

  return (
    <LoginBox>
      {identity?.user ? (
        <Box>
          <Paragraph marginTop={0} marginBottom={'1em'}>
            <SmartLineBreak>
              <>You’re currently logged in as</>
              <>
                <Strong>{identity?.user?.email}</Strong>.
              </>
            </SmartLineBreak>
          </Paragraph>
          <Paragraph marginBottom={'0.2em'}>
            <SmartLineBreak>
              <>
                <Link href={'/'}>Go to the home page</Link>
              </>
              <>
                <Box
                  as={'span'}
                  marginInlineStart={'0.4em'}
                  marginInlineEnd={'0.7em'}
                >
                  or
                </Box>
                <Button onClick={identity?.logout} variant={'outline'}>
                  Log out
                </Button>
              </>
            </SmartLineBreak>
          </Paragraph>
        </Box>
      ) : (
        <Paragraph>You’re not currently logged in.</Paragraph>
      )}
    </LoginBox>
  );
};
