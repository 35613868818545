export const TRANS = {
  toggle: { en: 'Toggle visibility', fi: 'Vaihda näkyvyyttä' },
  invalidValueMissing: {
    en: 'This field is required',
    fi: 'Tämä kenttä on pakollinen',
  },
  invalidTooShort: {
    en: 'The text you entered is too short',
    fi: 'Antamasi teksti on liian lyhyt',
  },
  invalidTooLong: {
    en: 'The text you entered is too long',
    fi: 'Antamasi teksti on liian pitkä',
  },
  invalidTypeMismatchEmail: {
    en: 'The text you entered is not an email address',
    fi: 'Antamasi teksti ei ole sähköpostiosoite',
  },
  invalidTypeMismatchUrl: {
    en: 'The text you entered is not a URL',
    fi: 'Antamasi teksti ei ole URL-osoite',
  },
  invalidPatternMismatch: {
    en: 'The text you entered does not match the expected pattern',
    fi: 'Antamasi teksti ei vastaa odotettua muotoilua',
  },
  invalidOther: {
    en: 'This field is invalid',
    fi: 'Tämä kenttä on virheellinen',
  },
};
