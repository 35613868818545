import React, { ReactElement } from 'react';
import { PageProps } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { LoggedIn, LoginForm, RegistrationForm } from '../components/Login';

const Login = ({ location }: PageProps): ReactElement => {
  const { hash } = location;
  const identity = useIdentityContext();
  const { user, urlToken } = identity;

  return user ? (
    <LoggedIn />
  ) : !hash && !urlToken ? (
    <LoginForm />
  ) : (
    <RegistrationForm />
  );
};

export default Login;
